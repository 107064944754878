import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import WebManage from "./admin/webManage";
import UserManage from "./admin/userManage";
import BusinessManage from "./admin/businessManage";
// import userBusiness from "@/router/user/business";
// import memberService from "./user/memberService";
// import userBoard from "@/router/user/board.js";
import notPageGuard from "@/router/notPageGuard";

Vue.use(VueRouter);

const routes = [
    // User
    {
        path: "/",
        component: () => import("@/views/StopPage.vue"),
    },
        // children: [
    //         {
    //             path: "",
    //             name: "index",
    //             component: () => import("@/views/user/Index.vue"),
    //         },
    //         {
    //             path: "/introduce/vision",
    //             name: "introduce",
    //             component: () => import("../views/user/introduce/CenterIntroduce.vue"),
    //         },
    //         {
    //             path: "/login",
    //             redirect: "/memberService/login"
    //         },
    //         {
    //             path: "/join",
    //             redirect: "/memberService/join/form"
    //         },
    //         ...userBusiness,
    //         ...userBoard,
    //         ...memberService,
    //     ],
    // },
    // Admin
    {
        path: "/admin",
        component: () => import("../views/admin/AdminPageGuard.vue"),
        children: [
            {
                path: "",
                name: "adminIndex",
                meta: { auth: "ADMIN" },
                component: () => import("../views/admin/Index.vue"),
            },
            ...WebManage,
            ...UserManage,
            ...BusinessManage,
        ],
    },
    // 페이지 가드가 필요없는 라우터 주소 (네비바, 풋터 미표시)
    ...notPageGuard
];

const router = new VueRouter({
    mode: "history",
    // 페이지 전환시 스크롤 상단 고정
    scrollBehavior() { 
        return { x: 0, y: 0 };
    },
    base: process.env.BASE_URL,
    routes,
});

/******************************************************************************
 *  @method     : Get cookie
 *  @param      { string }    :name
 *  @returns    { string }    :value
 *****************************************************************************/
function getCookie(name) {
    const value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return value ? value[2] : null;
}

/******************************************************************************
 *  @method     : JWT token decode
 *  @param      { string }
 *  @returns    { object }
 *****************************************************************************/
function jwtDecode(token) {
    try {
        const base64Payload = token.split(".")[1];
        const payload = decodeURIComponent(escape(window.atob(base64Payload)));
        const result = JSON.parse(payload.toString());
        return result;
    } catch {
        return false;
    }
}

/******************************************************************************
 *  Navigation Guard
 *****************************************************************************/
router.beforeEach((to, from, next) => {
    // 접근 권한이 필요한 경우
    if ("auth" in to.meta) {
        const authToken = getCookie("token");
        // 쿠키에 저장된 토큰이 없을 경우
        if (!authToken) {
            // 접근 사용 타입에 따라서 라우팅처리
            return to.meta.auth === "ADMIN" ? next("/backOffice") : next("/login");
        }
        // 토큰을 디코딩하여 정보를 파싱
        const tokenDecode = jwtDecode(authToken);
        // 접근권한이 없는 페이지를 접속할려는 경우
        if (to.meta.auth !== tokenDecode.role) {
            return to.meta.auth === "ADMIN" 
                                ? next("/backOffice?expireToken=true")
                                : next("/login?expireToken=true");
        }
        // 토큰 디코딩 실패
        if (!tokenDecode) {
            // 접근 사용 타입에 따라서 라우팅처리
            return to.meta.auth === "ADMIN" ? next("/backOffice") : next("/login");
        }
        // 토큰이 만료되었을 경우
        if (Date.now() > tokenDecode.exp * 1000) {
            return to.meta.auth === "ADMIN" 
                                ? next("/backOffice?expireToken=true")
                                : next("/login?expireToken=true");
            
        }
        // 로그인 상태 처리
        if (!store.getters.userLogin.status) {
            store.commit("userLogin", tokenDecode);
        }
    } 
    next();
});

/******************************************************************************
 *  동일 라우터 주소로 호출시 에러 예외 처리
 *****************************************************************************/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {});
};

export default router;

